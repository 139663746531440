@import "~antd/dist/antd.less";

@primary-color: #f57f55;
@border-radius-base: 6px;

.ant-btn {
  display: inline-flex;
  align-items: center;
}

.ant-message {
  z-index: 1010101;
}

.ant-message-custom-content {
  display: inline-flex;
  align-items: center;
}

