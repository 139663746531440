.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 500px;
  height: 350px;
  border-radius: 12px;
  background-color: #0f7173;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}
.title h4 {
  color: #fff;
}

.body p {
  color: #fff;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 24px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background: #f57f55;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0 4px 4px 0;

  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0 4px 4px 0;
}
